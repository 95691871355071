import React from 'react';

import Layout from '../components/Layout';

import antsPic from '../assets/images/eco_ant_bait.jpg';
import bedBugsPic from '../assets/images/eco_bed_bug.jpg';
import roachesPic from '../assets/images/eco_roaches.jpg';
import beeHivePic from '../assets/images/eco_bee_hive_break_out.jpg';
import beeHoneyCombPic from '../assets/images/eco_honey_comb.jpg';
import powerWashBirdPic from '../assets/images/eco_power_wash_bird.jpg';
import birdExclusionPic from '../assets/images/eco_bird_exclusion.jpg';

const ServicesPage = () => (
  <Layout fullMenu>
    <article id="main">
      <header>
        <h2>Services</h2>
        <p>&nbsp;</p>
      </header>
      <section className="wrapper style5">
        <div className="inner">
          <section>
            <h4>Ants, Roaches &amp; Bed Bugs</h4>
            <div className="box alt">
              <div className="row gtr-50 gtr-uniform">
                <div className="col-4">
                  <span className="image fit">
                    <img src={antsPic} alt="Ants" />
                  </span>
                </div>
                <div className="col-4">
                  <span className="image fit">
                    <img src={bedBugsPic} alt="Bed Bugs" />
                  </span>
                </div>
                <div className="col-4">
                  <span className="image fit">
                    <img src={roachesPic} alt="Roaches" />
                  </span>
                </div>
              </div>
            </div>
          </section>
          <section>
            <h4>Bee Hive Removal &amp; Remediation</h4>
            <div className="box alt">
              <div className="row gtr-50 gtr-uniform">
                <div className="col-6">
                  <span className="image fit">
                    <img src={beeHivePic} alt="Bee Hive" />
                  </span>
                </div>
                <div className="col-6">
                  <span className="image fit">
                    <img src={beeHoneyCombPic} alt="Honey Comb" />
                  </span>
                </div>
              </div>
            </div>
          </section>
          <section>
            <h4>Bird Exclusion</h4>
            <div className="box alt">
              <div className="row gtr-50 gtr-uniform">
                <div className="col-6">
                  <span className="image fit">
                    <img
                      src={birdExclusionPic}
                      alt="Bird Exclusion installation"
                    />
                  </span>
                </div>
                <div className="col-6">
                  <span className="image fit">
                    <img
                      src={powerWashBirdPic}
                      alt="Power Wash to remove bird feces"
                    />
                  </span>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </article>
  </Layout>
);

export default ServicesPage;
